<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
import orderConfig from '@/orderConfig'
import { orderStateList } from '@/utils/textFile'

export default {
  name: 'offlinePay',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmOrderInvoice/page',
      current: 1,
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号',
        },
        {
          name: '发票类型',
          key: 'invoiceType',
          type: 'select',
          typeData: [
            {
              text: "蓝票",
              name: "蓝票",
              value: 1,
            },
            {
              text: "红票",
              name: "红票",
              value: 2,
            },
          ],
        },
      ]
    },
    // 获取选中发票详情
    getDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/farmOrderInvoice/detail?id=${id}`)
          .then((result) => {
            resolve(result.data)
          })
          .catch((e) => reject && reject(e))
      })
    },
    // 退款事件
    onRefund({ records, item }) {
      let that = this
      // type手动传参 告诉当前订单类型
      orderConfig.refund({
        data: records,
        params: { data: { ...records, type: 'eatDrink' } },
        executeFun: [
          // 基础
          orderConfig.base,
          // 优惠券
          orderConfig.coupons,
          // 售后
          orderConfig.afterProcessing,
          // 记录
          orderConfig.record,
        ],
        success({ data, setHidden }) {
          api.order.refund
            .call(that, {
              url: '/agr/order/refund',
              params: { ...item, orderId: item.orderId, ...data },
            })
            .then(setHidden)
        },
      })
    },
    onClose(data) {
      orderConfig.close({
        data,
        params: {
          data,
        },
        executeFun: [
          // 表格数据
          orderConfig.table,
          // 基础信息
          orderConfig.base,
          // 记录
          orderConfig.record,
        ],
        success: ({ setHidden }) => {
          api.order.close
            .call(this, {
              url: '/common/order/close',
              params: { id: data.id },
            })
            .then(setHidden)
        },
      })
    },
    getColumns() {
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'invoiceType',
          title: '发票类型',
          type: 'badge',
          onExport: (text) => {
            return {
              1: '蓝票',
              2: '红票',
            }[text]
          },
          render(data) {
            return {
              showDot: true,
              name:
                {
                  1: '蓝票',
                  2: '红票',
                }[data] || '',
              color:
                {
                  1: 'blue',
                  2: 'red',
                }[data] || '',
            }
          },
        },
        {
          dataIndex: 'invoiceAmount',
          title: '申请金额',
          align: 'left',
        },
        {
          dataIndex: 'createTime',
          title: '申请时间',
          align: 'left',
        },
        {
          dataIndex: 'remark',
          title: '备注',
          align: 'left',
        },
        {
          dataIndex: 'invoiceStatus',
          title: '开票状态',
          type: 'badge',
          align: 'center',
          onExport: (text) => {
            return {
              0: '未开票 ',
              1: '开票中',
              2: '开票成功',
              3: '开票失败',
            }[text]
          },
          filters: [
            {
              text: '未开票',
              value: '0',
            },
            {
              text: '开票中',
              value: '1',
            },
            {
              text: '开票成功',
              value: '2',
            },
            {
              text: '开票失败',
              value: '3',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name:
                {
                  0: '未开票 ',
                  1: '开票中',
                  2: '开票成功',
                  3: '开票失败',
                }[data] || '',
              color:
                {
                  0: 'orange',
                  1: 'blue',
                  2: 'green',
                  3: 'red',
                }[data] || '',
            }
          },
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '开票信息',
                display:true,
                onClick: () => {
                  this.onRowSelect(records)
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    // 批量删除
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.id) },
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete,
            },
          ],
        },
      ]
    },
    onRowSelect(records) {
      const ext = [
        // 基本信息
        orderConfig.invoices,
        // 接收信息
        orderConfig.invoicesAccept,
      ]
      this.getDetailData(records.id).then((e) => {
        orderConfig.open({
          title:"开票信息",
          data: e,
          params: { data: e },
          executeFun: ext,
        })
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        // onSelectRow={this.onRowSelect}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
</style>
